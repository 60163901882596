import { css } from "@emotion/react";

export const headerItem = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  width: calc(100% + 1rem);
`;
