import type { RefObject } from "react";
import { useState, useEffect } from "react";

const useScrollBottomSpy = (ref: RefObject<HTMLDivElement>) => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const handleScroll = () => {
    if (!ref.current) return;

    const scrollContainer = ref.current;
    const { scrollTop, scrollHeight, offsetHeight } = scrollContainer;
    const isScrolledToBottom = scrollHeight - scrollTop <= offsetHeight;
    setIsAtBottom(isScrolledToBottom);
    setIsAtTop(scrollTop <= 0);
  };

  useEffect(() => {
    handleScroll();
  });

  return { isAtBottom, isAtTop, handleScroll };
};

export { useScrollBottomSpy };
