import { css, type Theme } from "@emotion/react";

import { utilities } from "@styles";
import { ellipsesOverflowHelper, removeClickOutline } from "@styles/emotion-styles/helpers";

export const dropdownItem = css`
  ${utilities.transition};
  transform-style: initial;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  margin-top: 1px;
  margin-bottom: 1px;
  white-space: nowrap;
  width: 100%;
  max-width: 16rem;
  border-radius: 4px;
  ${removeClickOutline};
`;

export const truncateChild = css`
  width: 100%;
  max-width: 16rem;
  ${ellipsesOverflowHelper};

  // for Checkbox and Radio. Not inheriting due to flexbox and multiple children
  & label {
    display: flex;
    position: unset;

    div:nth-of-type(2) {
      ${ellipsesOverflowHelper};
    }
  }
`;

export const noMaxWidth = css`
  max-width: none;
`;

const multiLineStyles = css`
  white-space: pre-wrap;
  overflow: initial;
  text-overflow: initial;
`;

export const applyMultiLine = css`
  ${multiLineStyles};

  & label {
    div:nth-of-type(2) {
      ${multiLineStyles};
    }
  }
`;

export const color = {
  default: (theme: Theme) => css`
    color: ${theme.text.body};
    background-color: ${theme.surface.interactiveBackground};
    border: 1px solid transparent;

    &:hover {
      color: ${theme.text.emphasis};
      background-color: ${theme.system.backgroundMuted};
    }
    &:active {
      color: ${theme.text.emphasis};
      background-color: ${theme.system.backgroundMutedActive};
    }
    &:focus {
      color: ${theme.text.emphasis};
      background-color: ${theme.surface.interactiveBackground};
      border: 1px solid ${theme.input.borderActive};
    }
  `,
  danger: (theme: Theme) => css`
    color: ${theme.danger.textBase};
    background-color: ${theme.surface.interactiveBackground};
    border: 1px solid transparent;

    &:hover {
      color: ${theme.danger.textHover};
      background-color: ${theme.danger.backgroundMuted};
    }
    &:active {
      color: ${theme.danger.textActive};
      background-color: ${theme.danger.backgroundMutedActive};
    }
    &:focus {
      color: ${theme.danger.textActive};
      background-color: ${theme.surface.interactiveBackground};
      border: 1px solid ${theme.input.borderActive};
    }
  `,
};

export const disabled = (theme: Theme) => css`
  cursor: not-allowed;
  color: ${theme.disabled.text};
  background-color: ${theme.disabled.background};

  &:hover,
  &:focus,
  &:active {
    color: ${theme.disabled.text};
    background-color: ${theme.disabled.background};
    border-color: transparent;
  }
`;

export const highlighted = (theme: Theme) => css`
  background-color: ${theme.system.backgroundMuted};
`;

export const textDirection = {
  left: css`
    text-align: left;
  `,
  right: css`
    flex-direction: row-reverse;
    text-align: right;
  `,
};

export const spacer = {
  base: css`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `,
  start: css`
    margin-right: 1rem;
  `,
  end: css`
    min-width: 2rem;
  `,
};
