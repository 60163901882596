import type { HTMLProps } from "react";

import * as styles from "./DropdownDivider.styles";

interface Props extends HTMLProps<HTMLHRElement> {
  /**
   * Optionally remove the top margin of the divider
   * Used when the item above it is NOT a DropdownItem/DropdownLink
   *
   */
  hasTopMargin?: boolean;
}

const DropdownDivider = ({ hasTopMargin = true, ...rest }: Props) => {
  const dividerStyles = [styles.dropdownDivider, hasTopMargin && styles.marginedTop];

  return <hr css={dividerStyles} {...rest} />;
};

export { DropdownDivider };
