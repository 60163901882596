import { css } from "@emotion/react";

export const dropdown = css`
  position: relative;
  display: inline-block;
  min-width: fit-content;
`;

export const isFullWidth = css`
  width: 100%;
`;

const absoluteRight = "-15.25px";
const absoluteLeft = absoluteRight;
const absoluteTop = "-7px";
const absoluteBottom = absoluteTop;

export const position = {
  kebab: {
    topRight: css`
      position: absolute;
      right: ${absoluteRight};
      top: ${absoluteTop};
    `,

    bottomRight: css`
      position: absolute;
      right: ${absoluteRight};
      bottom: ${absoluteBottom};
    `,

    bottomLeft: css`
      position: absolute;
      left: ${absoluteLeft};
      bottom: ${absoluteBottom};
    `,

    topLeft: css`
      position: absolute;
      left: ${absoluteLeft};
      top: ${absoluteTop};
    `,
    default: css`
      position: relative;
    `,
  },
};
