import { useState } from "react";

type Value<T> = T | (() => T);
type SetValueFn<T> = (nextState: T | ((prevState: T) => T)) => void;

interface ControlledOptions<T> {
  value: Value<T>;
  setValue: SetValueFn<T>;
}

const isFunction = <T>(value: Value<T>): value is () => T => {
  return typeof value === "function";
};

const hasValue = <T>(controlledOptions: ControlledOptions<T>) => {
  return typeof controlledOptions?.value !== "undefined" && controlledOptions?.setValue;
};

const useControlledState = <T>(
  initialState: Value<T>,
  controlledOptions?: ControlledOptions<T>
): [T, SetValueFn<T>] => {
  const [uncontrolledState, setUncontrolledState] = useState(initialState);
  if (hasValue(controlledOptions)) {
    const setValue: SetValueFn<T> = value => {
      controlledOptions.setValue(value);
      setUncontrolledState(value);
    };

    if (isFunction(controlledOptions.value)) {
      return [controlledOptions.value(), setValue];
    }

    return [controlledOptions.value, setValue];
  }

  return [uncontrolledState, setUncontrolledState];
};

export { useControlledState };
