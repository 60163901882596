import { css, type Theme } from "@emotion/react";

export const dropdownDivider = (theme: Theme) => css`
  width: calc(100% + 1rem);
  height: 1px;
  background-color: ${theme.separator.base};
  margin: 0rem -0.5rem 0.25rem;
`;

export const marginedTop = css`
  margin: 0.25rem -0.5rem 0.25rem;
`;
