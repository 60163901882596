import { css, type Theme } from "@emotion/react";

import { animations, dimensions, layers, timing, helpers } from "@styles";
export const dropdownContent = (theme: Theme) => css`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 10.25rem;
  padding: 0.5rem;
  background-color: ${theme.surface.interactiveBackground};
  border-radius: ${dimensions.borderRadius};
  box-shadow: ${theme.boxShadows.popoverBase};
  z-index: ${layers.indexDropdown};
  min-width: 200px;

  &:after {
    content: "";
    display: block;
    transition: opacity ${timing.defaultTiming} ${animations.easeOutCubic};
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 1rem;
    border-radius: 0 0 ${dimensions.borderRadius} ${dimensions.borderRadius};
    z-index: ${layers.zIndexLowForeground};
    background: linear-gradient(
      180deg,
      ${helpers.hexToRgba(theme.shadow.base, 0)} 0%,
      ${helpers.hexToRgba(theme.shadow.base, 0.1)} 90%
    );
  }
`;

export const scrolledToBottom = css`
  &:after {
    opacity: 0;
  }
`;

export const bottomScrollOffset = offset => css`
  &:after {
    bottom: ${offset}px;
  }
`;

export const popper = css`
  z-index: ${layers.indexDropdown};
  max-width: calc(100vw - 0.5rem);
  position: absolute;
`;

export const isFullWidth = css`
  min-width: 100% !important;
`;

export const isFullHeight = css`
  max-height: none;
  height: 100%;
`;

export const customWidth = (width: number) => css`
  width: ${width}rem;
`;

export const customHeight = (height: number) => css`
  height: ${height}rem;
  max-height: ${height}rem;
`;
